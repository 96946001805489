<div class="row feature-wrapper">
  <div class="col-12 mb-4">
    <span class="section-heading">More Items</span>
  </div>
  <ng-container *ngFor="let item of menuItems">
    <div class="col-12 col-md-6 col-lg-3"
      [class.d-none]="!(item.authorizedRoles?.includes('all') || item.authorizedRoles?.includes(this.role))"
      *ngIf="((!item.hide) && item.key != 'search' && item.key != 'ipds-v1' && item.key != 'checkEligibility' && item.key != 'fuel') 
      || (item.key == 'ipds-v1' && role != 'underwriter' && !lockstatus) 
      || (item.key == 'checkEligibility' && role != 'underwriter' && !lockstatus) || (item.key == 'fuel' && this.authRole === 'csm')" (click)="handleMenuItemClick(item.key)">
      <div class="theme-card text-center">
        <div class="outer-container">
          <ng-container *ngIf="item.key == 'policy-servicing' && browserName === 'safari'">
            <a target="_blank" href="{{policyServicingURL}}" id="policy-servicing"
              class="d-flex w-100 justify-content-between align-items-center"
              *ngIf="item.key == 'policy-servicing' && policyServicingErrorMessage == ''">
              <img class="svg-md" src="{{item.iconPath}}">
              <div class="card-text">{{item.name}}</div>
            </a>
            <div *ngIf="item.key == 'policy-servicing' && policyServicingErrorMessage != ''"
              (click)="policyServicingError()">
              <img class="svg-md" src="{{item.iconPath}}">
              <div class="card-text">{{item.name}}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.key == 'fuel' || item.key == 'ipds-v1' || item.key == 'checkEligibility'">
            <a target="_blank" id="{{item.key}}" class="d-flex w-100 justify-content-between align-items-center">
              <img class="svg-md" src="{{item.iconPath}}">
              <div class="card-text">{{item.name}}</div>
            </a>
          </ng-container>
          <ng-container *ngIf="(!item.hide) && (item.key != 'policy-servicing' || (item.key == 'policy-servicing' && browserName !== 'safari')) 
     && (item.key != 'ipds-v1' && item.key != 'checkEligibility' && item.key != 'fuel')">
            <img class="svg-md" [src]="item.iconPath" alt="">
            <div class="card-text">{{ item.name }}</div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngFor="let item of menuItemsUconWrapperCheck">
    <div class="col-12 col-md-6 col-lg-3" *ngIf="item.authorized" (click)="redirectUconWrapper(item.key)">
      <div class="theme-card text-center">
        <div class="outer-container">
          <div class="">
            <img alt="" class="img-fluid svg-lg" [src]="item.iconPath" />
          </div>
          <div class="card-text">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>