import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { ALLEACH_CONST, API_END_POINTS } from './constants';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class BaseServiceService {
  //SET UP ENVIRONMENT HERE
  public origin: any = window.location.origin;
  public environment: string = environment.environment;
  public url = environment.url;
  public arr = environment.url.split("/");
  public result = this.arr[0] + "//" + this.arr[2];

  public token: string;
  firstname: any; // venmani
  email: any; // venmani
  loginrole: any; // venmani
  last_login: any; // venmani
  sol_id: any;
  public sitekey: string = "6LestNwZAAAAAF0jT-YI5Ay1jrA6CdrcwqPfBMBv";

  public headers = {
    "in-auth-token": sessionStorage.getItem('in-auth-token') == null ? '' : sessionStorage.getItem('in-auth-token'),
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  }
  public ctoken_headers = {
    "in-auth-token": sessionStorage.getItem('in-auth-token') == null ? '' : sessionStorage.getItem('in-auth-token'),
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    "ctoken": ""
  }
  public newheaders = {
    "in-auth-token": sessionStorage.getItem('in-auth-token') == null ? '' : sessionStorage.getItem('in-auth-token'),
    "Content-Type": "application/json",
  }

  cv_productId= environment.cv_productId;
  lta2_productId = environment.lta2_productId;
  SecUrl: string = environment.SecUrl;
  SecUrl_two: string = environment.SecUrl_two;
  SecUrl_three: string = environment.SecUrl_three;
  productnavigationurl: string = environment.productnavigationurl;
  url_one: string = environment.url_one;
  url_two: string = environment.url_two;
  url_three: string = environment.url_three;
  panel_navigate: string;
  producer_code: string;
  location: string;
  locationlist: any;
  brokerlist: any;
  deposit_balance: any;
  ctoken: any;
  tempdatalocation: any;

  previousRoute = '';
  currentRoute = '';
  browserName = '';
  cyberSheild = environment.cyberSheild;
  personalLinesClaimsUrl = environment.personalLinesClaimsUrl;
  nonRaterProductId = environment.nonRaterProductId;
  ipdsv1Navigation = environment.ipdsv1Navigation;
  customerDiaryProducts = environment.customerDiaryProducts;
  dAndOProductId = environment.dAndOProductId;
  gagProductId = environment.gagProductId;
  bgrNeumorphicProductId = environment.bgrNeumorphicProductId;
  marineToolProductId = environment.marineToolProductId;
  comboMaster= environment.comboMaster;
  fuelPath= environment.fuelPath;

  url_npm: string = environment.url_npm;
  ctoken_nav_url: string = environment.ctoken_nav_url;
  aigcv2_product_id: string = environment.aigcv2_product_id;
  home_product_id: string = environment.home_product_id;
  aigCombined: string = environment.aigCombined;
  navigation_token: string = environment.navigation_token;
  workman_product_id: string = environment.workman_product_id;
  productArray = environment.productArray;
  workman_path = environment.workman_path;
  non_rater_product_group_id=  environment.non_rater_product_group_id;
  customerDiaryPath = environment.customerDiaryPath;
  commonIconPath = environment.commonIconPath;

  constructor(public http: HttpClient, public router: Router, public api: ApiService, private _sessionStorage: SessionStorageService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousRoute = this.currentRoute;
        this.currentRoute = event.url;
        window.dispatchEvent(new HashChangeEvent("hashchange"));
      }
    });
  }

  get baseURLForMotorLob() {
    return this.environment === 'UAT' ? 'https://uatipds3.cloware.in/api/v1/' : 'https://ipds3.cloware.in/api/v1/'
  }
  checklogin() {
    if (sessionStorage.getItem('in-auth-token') === '' || sessionStorage.getItem('in-auth-token') === undefined
      || sessionStorage.getItem('in-auth-token') === null) {

      this.router.navigate(['/login']);
    } else {
      console.info('login success');
    }
  }
  async getParamValueQueryString(paramName) {
    let url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      let httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
  /* venmani*/
  //  For authentication purpose
  auth(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.auth, data);
  }
  // getNewsRole(data): Observable <any>{
  //   return this.http.get(environment.url + API_END_POINTS.getNewsRole + data, { headers : new HttpHeaders(this.headers)});
  // }
  getAllNews(data):Observable<any>{
    return this.http.get(environment.url+ API_END_POINTS.getNews + data, {headers: new HttpHeaders(this.headers)});
  }

  producerValidity(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.producerValidity , data, { headers: new HttpHeaders(this.headers) });
  }

  checkUser(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.checkUser, data, { headers: new HttpHeaders(this.headers) });
  }

  insertUser(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.insertUser, data, { headers: new HttpHeaders(this.headers) });
  }

  updateUser(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.updateUser, data, { headers: new HttpHeaders(this.headers) });
  }

  makeHistory(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.makeHistory, data, { headers: new HttpHeaders(this.headers) });
  }
  brokerInsert(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.brokerInsert, data, { headers: new HttpHeaders(this.headers) });
  }
  profile(): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.profile);
  }
  userlist(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.userList, { headers: new HttpHeaders(this.headers) });
  }
  usersingle(id): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.userSingle + id, { headers: new HttpHeaders(this.headers) });
  }
  brokerList(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.brokerList, { headers: new HttpHeaders(this.headers) });
  }
  // http://dev1.tataaig.com/api/v1/transact/singleproducer/rajesh.eee@godbtech.com;
  singleproducer(data): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.singleproducer + data.data);
    //return this.http.get(environment.url + 'transact/singleproducer/' + data.data, { headers: new HttpHeaders(this.headers) });
  }
  navigateToTwMva(data): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.navigateToMva + data,false,environment.SecUrl_two);
  }
  DataEntryUserAdminInsert(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.dataEntryUserAdminInsert, data, false, this.api.newheaders);
    //return this.http.post(environment.url + 'transact/dataEntryInsert', data, { headers: new HttpHeaders(this.newheaders) });
  }
  BrokerUserCreationInsert(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.brokerUserCreationInsert, data, { headers: new HttpHeaders(this.newheaders) });
  }

  getAutoloadUserData(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getAutoloadUserData, data, { headers: new HttpHeaders(this.newheaders) });
  }
  DataEntryUserAdminList(): Observable<any> {
    if (this.headers['in-auth-token']) {
      return this.http.get(environment.url + API_END_POINTS.dataEntryUserAdminList, { headers: new HttpHeaders(this.headers) });
    } else {
      this.router.navigateByUrl(`/login`);
    }
  }
  // http://dev1.tataaig.com/api/v1/transact/singleproducer/rajesh.eee@godbtech.com
  DataEntryUserAdminSingle(data): Observable<any> {
    if (this.headers['in-auth-token']) {
      return this.api.httpGetMethod(API_END_POINTS.dataEntryUserAdminSingle + data)
      //return this.http.get(environment.url + 'transact/dataEntryUser/' + data, { headers: new HttpHeaders(this.headers) });
    } else {
      this.router.navigateByUrl(`/login`);
    }
  }

  ipds_auth(data): Observable<any> {
    return this.http.post(environment.SecUrl + API_END_POINTS.auth, data);
  }

  profile_ipds(headers): Observable<any> {
    return this.http.get(environment.SecUrl + API_END_POINTS.profile, { headers: new HttpHeaders(headers) });
  }
  profile_ipds_two(headers): Observable<any> {
    return this.http.get(environment.SecUrl_two + API_END_POINTS.profile, { headers: new HttpHeaders(headers) });
  }
  profile_ipds_three(headers): Observable<any> {
    return this.http.get(environment.SecUrl_three + API_END_POINTS.profile, { headers: new HttpHeaders(headers) });
  }

  getRoles(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getRoles, { headers: new HttpHeaders(this.headers) });
  }
  getLobs(){
    return this.api.httpGetMethod(API_END_POINTS.allActiveLob);
  }
  getProducts(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getProducts, { headers: new HttpHeaders(this.headers) });
  }
  receiptDelete(url): Observable<any> {
    return this.http.post(url,{}, { headers: new HttpHeaders(this.newheaders) });
  }
  productPanel(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.productPanel, { headers: new HttpHeaders(this.headers) });
  }
  getactiveBrokers(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getactiveBrokers, { headers: new HttpHeaders(this.headers) });
  }
  getcToken(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.encryptProposal, data, false, this.api.newheaders );
    // return this.http.post(environment.url + 'commonmasterdata/encryptproposal?', data, { headers: new HttpHeaders(this.newheaders) });
  }
  insertProduct(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.insertProduct, data, { headers: new HttpHeaders(this.newheaders) });
  }
  insertLob(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.insertLob, data, { headers: new HttpHeaders(this.newheaders) });
  }
  insertrole(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.insertRole, data, { headers: new HttpHeaders(this.newheaders) });
  }
  getlobLevel(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getLobLevel, data, { headers: new HttpHeaders(this.newheaders) });
  }
  sendOtp(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.otp, data, { headers: new HttpHeaders(this.newheaders) });
  }
  getLocationMapped(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.brokerLocationMappedProducer, data, { headers: new HttpHeaders(this.newheaders) });
    // return this.http.post(environment.url + 'transact/gc_location', data, { headers: new HttpHeaders(this.newheaders) });
  }
  getproducerLocationMapped(data): Observable<any> {
    // return this.http.post(environment.url + 'transact/broker_location_mapped_producers', data, { headers: new HttpHeaders(this.newheaders) });
    return this.http.post(environment.url + API_END_POINTS.getProducerLocMapped, data, { headers: new HttpHeaders(this.newheaders) });
  }
  getUserLocationMapped(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getUserDetails, data, { headers: new HttpHeaders(this.newheaders) });
  }
  ntprofile(headers): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.profile, { observe: "response", responseType: "json", headers: new HttpHeaders(headers) });
  }

  getdeodomain(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getDeoDomain, data, { headers: new HttpHeaders(this.newheaders) });
  }
  getRolename(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.roleName, data, false, this.api.newheaders );
  }
  getRoledetails(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.roleDetails, data, false, this.api.newheaders );
  }
  getdeodetails(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getDeoDetails, data, { headers: new HttpHeaders(this.newheaders) });
  }
  getParentProduct(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getParentProduct, data, { headers: new HttpHeaders(this.newheaders) });
  }

  // new
  getParentPayMode(data) {
    return this.api.httpPostMethods(API_END_POINTS.getParentPayMode, { parent_code: data }, false, this.api.newheaders);
    //return this.http.post(environment.url + 'transact/getparentPaymentMode', { parent_code: data }, { headers: new HttpHeaders(this.newheaders) });
  }

  getLob(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getParentProduct, {
      producer_code: data
    }, { headers: new HttpHeaders(this.newheaders) });
  }

  getpcandloc(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getLocationCode, data, { headers: new HttpHeaders(this.newheaders) });
  }

  /* Jayaraman */
  getLobProduct(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.getLobProduct, data, false, this.api.newheaders);
    //return this.http.post(environment.url + 'transact/lob_based_products', data, { headers: new HttpHeaders(this.newheaders) });
  }
  getAutoloadData(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.getAutoloadData, data, false, this.api.newheaders);
    //return this.http.post(environment.url + 'transact/getParentextraDetail', data, { headers: new HttpHeaders(this.newheaders) });
  }

  /*venmani */
  notification(): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.notification, {}, { headers: new HttpHeaders(this.headers) });
  }
  getallLobs(email): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getAllLob + email, { headers: new HttpHeaders(this.headers) });
  }
  checkbrokervalid(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.checkBrokerValid, data);
  }
  checkdeobrokervalid(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.checkDeoBrokerValid, data);
  }
  checkuservalid(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.postCheckUserValid, data);
  }
  callmasterlob(lob): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.masterLobMap + lob);
  }
  getchanneldata(): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.allProducerChannel);
    // return this.http.get(environment.url + 'transact/all_producer_channel', { headers: new HttpHeaders(this.headers) });
  }
  umbroker(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.umbroker, data, false, this.api.newheaders );
    //return this.http.post(environment.url + 'transact/um_producer', data, { headers: new HttpHeaders(this.newheaders) });
  }
  updateleopro(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.updateDeoProLoc, data, { headers: new HttpHeaders(this.newheaders) });
  }

  DeactiveStatus(data) {
    return this.api.httpPostMethods(API_END_POINTS.deactiveStatus, {
      email_id: data
    } , false , this.api.newheaders);
    // return this.http.post(environment.url + 'transact/deactivatedeouser', {
    //   email_id: data
    // }
    //   , { headers: new HttpHeaders(this.newheaders) })
  }

  ActivateStatus(data) {
    return this.api.httpPostMethods(API_END_POINTS.activateStatus,{
         email_id: data
       }, false, this.api.newheaders);
    // return this.http.post(environment.url + 'transact/activatedeouser', {
    //   email_id: data
    // }, { headers: new HttpHeaders(this.newheaders) })
  }

  // DataEntryUser(data){
  //   //return this.http.get('http://dev1.tataaig.com/api/v1/transact/dataEntryUser/'+data,{ headers: new HttpHeaders(this.newheaders) })
  //   return this.http.get(environment.url + 'transact/dataEntryUser'+data.data, { headers: new HttpHeaders(this.newheaders) });
  // }

  DataEntryUser(data): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.dataEntryUser + data);
    //return this.http.get(environment.url + 'transact/dataEntryUser/' + data, { headers: new HttpHeaders(this.headers) });
  }
  //venmani
  getorthedomainemailcheck(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.checkDomainEmail, data);
  }
  getotpalaisgenerate(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getOtpAlaisGenerate, data, { headers: new HttpHeaders(this.headers) });
    // return this.http.post(environment.url + 'auth/otp?alias=', [data]);
    // return this.http.get(environment.url + 'auth/otp?alias='+ data);
    // [alias-email]
  }
  getRenewalNavigation(data): Observable<any>{
    return this.http.get(environment.url + API_END_POINTS.getRenewalNavigation +data, { headers: new HttpHeaders(this.headers)});
  }
  getotpandmailcheckauth(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getOtpAndMailCheckAuth, data, { headers: new HttpHeaders(this.headers) });
  }
  getParentextraDetail(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getParentExtraDetail, data, { headers: new HttpHeaders(this.newheaders) });
  }
  producerlockingStatus(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.producerLocking, data, { headers: new HttpHeaders(this.newheaders) }).pipe(timeout(60000));
  }
  producerlockingDateAmount(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.syncProducerBalance, data, { headers: new HttpHeaders(this.newheaders) }).pipe(timeout(60000));
    //cmd 03-12-2020 return this.http.post(environment.url + 'transact/producerlocking', data, { headers: new HttpHeaders(this.newheaders) });
  }
  view_transationList(data): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.viewTransactionList + data);
  }

  view_transationLob(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.viewTransactionLob, data);
  }

  //venmani
  lob_Group(data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.lobGroupMap + data, { headers: new HttpHeaders(this.headers) });
  }

  productCount(envUrl, apiEndPoint): Observable<any> {
    return this.api.httpGetMethod(apiEndPoint, false, envUrl);
    // return this.http.get(data, { headers: new HttpHeaders(this.headers) });
  }

  Product_grpID(data): Observable<any> {
    return this.http.get(data, { headers: new HttpHeaders(this.headers) });
  }

  receipt_search(url, body): Observable<any> {
    return this.http.post(url, body, { headers: new HttpHeaders(this.newheaders) });
  }

  /**************LTA muruga 23/10/2020*************** */
  WaatsPolicy(data): Observable<any> {
    return this.http.get(data, { headers: new HttpHeaders(this.headers) });
  }

  async httpPdfDownload(url, policyID) {
    const httpOptions = {
      headers: new HttpHeaders({
        'in-auth-token': sessionStorage.getItem('in-auth-token') != null ? sessionStorage.getItem('in-auth-token') : ''
      }),
      observe: 'response' as 'body',
      responseType: 'blob' as 'blob'
    };
    this.http.get(url, httpOptions)
      .toPromise()
      .then((response) => {
        let blob = new Blob([response["body"]], { type: 'application/pdf' });
        const data = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = policyID + ".pdf";
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 100);
      })
      .catch(err => Promise.reject(err.error || 'Server error'));
  }
  /***********muruga 26/10/2020**************** */
  takeOver(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.takeOver + data, {}, { headers: new HttpHeaders(this.newheaders) });
  }

  /*******rajesh********/
  locationRecord(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.locationRecord, { headers: new HttpHeaders(this.headers) });
  }

  masterRecord(data): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.masterRecord + data)
    //return this.http.get(environment.url + 'master/' + data, { headers: new HttpHeaders(this.headers) });
  }

  // login product code send
  // loginProducerEmailCheck(data):Observable<any> {
  //   return this.http.post(environment.url + 'auth/impersonate/'+data, { headers: new HttpHeaders(this.newheaders) });
  // }

  loginProducerCodeCheck(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.loginProducerCodeCheck, data, { headers: new HttpHeaders(this.newheaders) });
  }

  loginProducerEmailCheck(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.loginProducerEmailCheck + data, {}, { headers: new HttpHeaders(this.newheaders) });
  }

  /*************************/
  /**venmani* */
  lopicon(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.lobIcons, { headers: new HttpHeaders(this.headers) });
  }
  lopsideicon(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.lobSideIcons, { headers: new HttpHeaders(this.headers) });
  }
  producticon(): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.masterProductIcon, true)
    // return this.http.get(environment.url + 'master/product_groups/product_icons', { headers: new HttpHeaders(this.headers) });
  }
  searchnavigation(data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.searchNavigation + data, { headers: new HttpHeaders(this.headers) });
  }
  /**venmani */


  getLocation(data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getLocation + data, { headers: new HttpHeaders(this.headers) });
  }
  channelMapper(data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.channelMapper + data, { headers: new HttpHeaders(this.headers) });
  }

  bancaChannel(data): Observable<any> {
    let csmHeaders = sessionStorage.getItem('auth-role') !== ALLEACH_CONST.csm ? this.newheaders : {
      "in-auth-token": sessionStorage.getItem('auth-token') == null ? sessionStorage.getItem("in-auth-token") : sessionStorage.getItem('auth-token'),
      "Content-Type": "application/json",
    }

    return this.http.post(environment.url + API_END_POINTS.bancaChannel, data, { headers: new HttpHeaders(csmHeaders) });
  }
  getProdcutBasedPlan(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getProdcutBasedPlan, data, { headers: new HttpHeaders(this.newheaders) });
  }
  getPlanBasedZone(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getPlanBaseZone, data, { headers: new HttpHeaders(this.newheaders) });
  }
  get_product_based_plan(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.getProductBasedPlan, data, false, this.api.newheaders);
    //return this.http.post(environment.url + 'transact/product_based_plan', data, { headers: new HttpHeaders(this.newheaders) });
  }
  get_plan_based_zone(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.getPlanBasedZone, data, false, this.api.newheaders);
    //return this.http.post(environment.url + 'transact/plan_based_zone', data, { headers: new HttpHeaders(this.newheaders) });
  }

  // Master API For Product Plan Zone
  get_product_plan_zone_master(): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.productPlanZoneMaster, true, '', this.newheaders);
  }

  /*venmani*/
  product_searchnavigation(data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.productSearchNavigation + data, { headers: new HttpHeaders(this.headers) });
  }
  /* venmani*/

  getcsmlocationbrokers(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.getCsmLocationBroker, data, { headers: new HttpHeaders(this.newheaders) });
  }

  getEndorsementUrl(resource,data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getEndorsementUrl + resource + '&role=' + data.b_role + '&producerCode=' + data.b_code + '&producerMobileNumber=' + data.b_mobile + '&producerEmailId=' + data.b_email + '&producerName=' + data.b_name, { headers: new HttpHeaders(this.newheaders) });
  }
  getEndorsementCsmUrl(resource,data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getEndorsementUrl + resource + '&role=' + data.b_role + '&csmNtId=' + data.csmNtId + '&producerCode=' + data.b_code + '&producerMobileNumber=' + data.b_mobile + '&producerEmailId=' + data.b_email + '&producerName=' + data.b_name, { headers: new HttpHeaders(this.newheaders) });
  }

  getGmcEndorsementUrl(resource,data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getEndorsementUrl + resource + '&role=' + data.role + '&productName=' + data.productName + '&productCode=' + data.productCode + '&producerCode=' + data.producerCode + '&producerMobileNumber=' + data.producerMobileNumber + '&producerEmailId=' + data.producerEmailId + '&producerName=' + data.producerName + "&locationName="+ data.locationName + "&officeCode="+ data.officeCode + "&channelType="+ data.channelType +  "&imitatorEmail="+ data.imitatorEmail +  "&imitatorAuthRole="+ data.imitatorAuthRole +  "&imitatorRole="+ data.imitatorRole ,   { headers: new HttpHeaders(this.newheaders) });
  }
  getGmcEndorsementCsmUrl(resource,data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getEndorsementUrl + resource + '&role=' + data.role + '&productName=' + data.productName + '&productCode=' + data.productCode + '&producerCode=' + data.producerCode + '&producerMobileNumber=' + data.producerMobileNumber + '&producerEmailId=' + data.producerEmailId + '&producerName=' + data.producerName + "&locationName="+ data.locationName + "&officeCode="+ data.officeCode + "&channelType="+ data.channelType +  "&imitatorEmail="+ data.imitatorEmail +  "&imitatorAuthRole="+ data.imitatorAuthRole +  "&imitatorRole="+ data.imitatorRole ,   { headers: new HttpHeaders(this.newheaders) });
  }

  getmyRequestUrl(data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getMyRequestUrl + data.b_role + '&producerCode=' + data.b_code + '&producerMobileNumber=' + data.b_mobile + '&producerEmailId=' + data.b_email + '&producerName=' + data.b_name, { headers: new HttpHeaders(this.newheaders) });
  }
  getmyRequestCsmUrl(data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getMyRequestUrl + data.b_role + '&csmNtId=' + data.csmNtId + '&producerCode=' + data.b_code + '&producerMobileNumber=' + data.b_mobile + '&producerEmailId=' + data.b_email + '&producerName=' + data.b_name, { headers: new HttpHeaders(this.newheaders) });
  }

  getmyRequestSPAUrl(data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getmyRequestSPAUrl + data.b_role + '&producerCode=' + data.b_code + '&producerMobileNumber=' + data.b_mobile + '&producerEmailId=' + data.b_email + '&producerName=' + data.b_name, { headers: new HttpHeaders(this.newheaders) });
  }
  getmyRequestSPACsmUrl(data): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getmyRequestSPAUrl + data.b_role + '&csmNtId=' + data.csmNtId + '&producerCode=' + data.b_code + '&producerMobileNumber=' + data.b_mobile + '&producerEmailId=' + data.b_email + '&producerName=' + data.b_name, { headers: new HttpHeaders(this.newheaders) });
  }  

  getRenewalRedirect(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getRenewalRedirect, { headers: new HttpHeaders(this.newheaders) });
  }

  underwriterDeviation(data): Observable<any> {
    return this.http.post(environment.SecUrl_three + API_END_POINTS.underWriterDeviation, data, { headers: new HttpHeaders(this.newheaders) });
  }

  getRenewalProduct(): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.getRenewalProduct,{}, false, this.newheaders,environment.SecUrl_three );
  }

  producthovericon(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.productHoverIcon, { headers: new HttpHeaders(this.headers) });
  }

  cancelPolicy(policyId): Observable<any> {

    return this.http.get(environment.url + API_END_POINTS.cancelPolicy + policyId, { headers: new HttpHeaders(this.newheaders) })
  }

  quickLinks(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.quickLinks, { headers: new HttpHeaders(this.headers) });

  }

  role_search_criteria(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.roleSearchCriteria, data, { headers: new HttpHeaders(this.newheaders) });
  }
  /*Added By Jagadeesh */
  ctoken_profile(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.profile, { headers: new HttpHeaders(this.ctoken_headers) });
  }

  custom_profile(token): Observable<any> {
    var header_data = {
      "in-auth-token": token,
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    };
    return this.http.get(environment.url + API_END_POINTS.profile, { headers: new HttpHeaders(header_data) });
  }
  lob_group_map_dropdown(data): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.lobGroupMapDropdown + data)
    // return this.http.get(environment.url + 'master/product_groups/lob_group_map_dropdown/' + data, { headers: new HttpHeaders(this.headers) });
  }
  lob_group_map_advancepopup_dropdown(data): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.lobGroupMapPopupDropdown + data)
    // return this.http.get(environment.url + 'master/product_groups/lob_group_map_popup_dropdown/' + data, { headers: new HttpHeaders(this.headers) });
  }
  landingPage(productId): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.landingPage + productId, { headers: new HttpHeaders(this.headers) });
  }

  saverevalexcel(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.saverevalexcel, data, false, this.api.newheaders);
    //return this.http.post(environment.url + 'transact/revalDeoExcel', data, { headers: new HttpHeaders(this.newheaders) });
  }

  checkctokendatevalid(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.checkcTokenBrokerValid, data, { headers: new HttpHeaders(this.newheaders) });
  }

  policyService(policyId): Observable<any> {
    return this.http.get(environment.SecUrl_two + API_END_POINTS.policyService + policyId, { headers: new HttpHeaders(this.newheaders) })
  }

  public downloadPolicy(serverURL, policyId: string) {
    let encodeToken = '';
    encodeToken = encodeURIComponent(sessionStorage.getItem('in-auth-token'));
    const url = serverURL + '/api/v1/policy/data/' + policyId + '/policy.pdf/policy.pdf?download=1&token=' + encodeToken
    return window.open(url, "_blank")
  }

  public clone(param): Observable<any> {
    const quoteId = param?.quoteId;
    const version = param?.version || 1;
    return this.http.post(
      this.baseURLForMotorLob + 'quote/clone/' + quoteId,
      {
        no_version: version,
      },
      { headers: new HttpHeaders(this.newheaders) }
    );
  }
  public saveClonedData(productId, quoteId): Observable<any> {
    return this.http.post(this.baseURLForMotorLob + 'quote', {
      quoteId: quoteId,
      product_id: productId,
    }, { headers: new HttpHeaders(this.newheaders) });
  }

  public shareProposal(policy_id: string): Observable<any> {
    return this.http.get(
      `${this.baseURLForMotorLob}pcimportmaster/customquotepdf?ids=${policy_id}&type=share`, { headers: new HttpHeaders(this.newheaders) }
    )
  }

  public finalizeQuote(quote_id: string): Observable<any> {
    return this.http.post(`${this.baseURLForMotorLob}quote/finalize?quote_id=${quote_id}`, {}, { headers: new HttpHeaders(this.newheaders) })
  }

  public getDetailByQuoteId(quoteId = '') {
    return this.http.get(
      this.baseURLForMotorLob + API_END_POINTS.getDetailsByQuoteId + quoteId
      , { headers: new HttpHeaders(this.newheaders) });
  }

  public setPolicyMeta(body, policy_id) {
    return this.http.post(
      this.baseURLForMotorLob + API_END_POINTS.setPolicyMeta + policy_id,
      body
      , { headers: new HttpHeaders(this.newheaders) }
    );
  }
  // sendEmail(data): Observable<any> {
  //   return this.http.post(environment.url + 'generic_api/send_email', data, { headers: new HttpHeaders(this.newheaders) });
  // }
  public getPolicyStatus(body, policy_no) {
    return this.http.post(
      environment.url + API_END_POINTS.getPolicyStatus + policy_no,
      {}
      , { headers: new HttpHeaders(this.newheaders) }
    );
  }
  getBancaDirectProducer(data): Observable<any>{
    return this.http.get(environment.url + API_END_POINTS.getBancaDirectProducer + data ,{headers: new HttpHeaders(this.headers)});
  }

  getSolidForProducer(data):Observable<any>{
    return this.http.get(environment.url + API_END_POINTS.getSolIdForProducer +data ,{headers : new HttpHeaders(this.headers)});
  }

  getSPMaster(data):Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.getSPMaster + data, { headers: new HttpHeaders(this.headers) });
  }
  boltRedirection(data):Observable<any>{
    return this. http.post(environment.url + API_END_POINTS.boltRedirection ,data, { headers: new HttpHeaders(this.newheaders) });
  }

  gotgMasterPolicyList(params): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.gotgMasterPolicyList + params.producer_code, {}, { headers: new HttpHeaders(this.newheaders) }
      //return this.http.post( environment.url + 'GOTG/policy_list?producer_code=9594959495', {},  { headers: new HttpHeaders(this.newheaders) }
    );

  }

  personalAccidentPolicyList(params): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.personalAccidentPolicyList + params.producer_code, {}, { headers: new HttpHeaders(this.newheaders) }
      //return this.http.post( environment.url + 'GOTG/policy_list?producer_code=9594959495', {},  { headers: new HttpHeaders(this.newheaders) }
    );

  }

  get_dealer_details(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.getDealerDetails, data, false, this.api.newheaders);
    //return this.http.post(environment.url + 'generic_api/dealer_details', data, { headers: new HttpHeaders(this.newheaders) });
  }
  get_location_name(data): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.getLocationName + data);
    //return this.http.get(environment.url + 'transact/locationData?location_code=' + data, { headers: new HttpHeaders(this.newheaders) })
  }

  getCVRenewalShow(data): Observable<any> {
    return this.http.get(environment.SecUrl_three + API_END_POINTS.getCVRenewalShow + data, { headers: new HttpHeaders(this.newheaders) })
  }
  
  getLockedProposalList(data): Observable <any>{
    return this.http.post(environment.url + API_END_POINTS.getLockedProposalList ,data, {headers: new HttpHeaders(this.newheaders)});
  }
  getDomainName():Observable<any>{
    return this.http.get(environment.url + API_END_POINTS.getDomainName,{headers : new HttpHeaders(this.headers)});
  }
  getPayInSlip(data):Observable<any>{
    return this.http.get('https://' + data,{headers : new HttpHeaders(this.headers)});
  }
  downloadPayInSlip(data):Observable<any>{
    return this.http.get(environment.SecUrl_two+API_END_POINTS.downloadPayInSlip+data,{headers : new HttpHeaders(this.newheaders)});
  }

  fnBrowserDetect(){
                 
    let userAgent = navigator.userAgent;
    let browserName;
    
    if(userAgent.match(/chrome|chromium|crios/i)){
        browserName = "chrome";
      }else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox";
      }  else if(userAgent.match(/safari/i)){
        browserName = "safari";
      }else if(userAgent.match(/opr\//i)){
        browserName = "opera";
      } else if(userAgent.match(/edg/i)){
        browserName = "edge";
      }else{
        browserName="No browser detection";
      }
    this.browserName = browserName;
           
}

  // Features page
  validateEndorsementUser(producer_code): Observable<any>{
    return this.http.get(environment.SecUrl_two + API_END_POINTS.validateEndorsementUser+producer_code, {headers: new HttpHeaders(this.headers)});
  }
  // intellicus_token(data):Observable<any>{
  //   return this.http.post(environment.url + 'generic_api/getintellicus_token',data, {headers: new HttpHeaders(this.newheaders)});
  // }

  csmProfile(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.profile, { headers: new HttpHeaders({ "in-auth-token": sessionStorage.getItem('auth-token') == null ? '' : sessionStorage.getItem('auth-token'), "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }) });
  }

  encryption(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.encryption, data, { headers: new HttpHeaders({ "in-auth-token": sessionStorage.getItem('in-auth-token') == null ? '' : sessionStorage.getItem('in-auth-token'), "Content-Type": "application/json" }) });
  }
  //////Motor Feedback Survey ////////////

  feedbackSurvey(data): Observable<any> {
    return this.http.post(environment.SecUrl_three + API_END_POINTS.feedbackSurvey, data, { headers: new HttpHeaders(this.newheaders) });
  }
  feedbackSurveyStatus(data): Observable<any> {
    return this.http.post(environment.SecUrl_three + API_END_POINTS.feedbackSurveyStatus, data, { headers: new HttpHeaders(this.newheaders) });
  }
  bulkDeuUserCreation(data):Observable<any>{
    return this.api.httpPostMethods(API_END_POINTS.bulkDeuUserCreation, data, false, this.api.newheaders);
    //return this.http.post(environment.url + 'transact/insertsampledataentrybyexcelbatch',data, {headers: new HttpHeaders(this.newheaders)});
  }  
  // validateEDIUser(data): Observable <any>{
  //   return this.http.get(environment.SecUrl_two+ "qms_bre_integration_aigc/bancaUserallowed?producer_code="+data, {headers : new HttpHeaders(this.headers)});
  // }

  ///// Terms and Conditions

  termsConditionStatus(data):Observable<any>{
    return this.http.post(environment.url + API_END_POINTS.termsConditionStatus,data, {headers: new HttpHeaders(this.newheaders)});
  } 
  termsConditionInsert(data):Observable<any>{
    return this.http.post(environment.url + API_END_POINTS.termsConditionInsert,data, {headers: new HttpHeaders(this.newheaders)});
  } 
  subTransctionType(data):Observable <any>{
    return this.api.httpGetMethod(API_END_POINTS.subTransactionType + data, false, environment.SecUrl_two);
  }

  nonSme(data):Observable<any>{
    return this.api.httpPostMethods(API_END_POINTS.nonSme + data,{});
  }

  //nsm discounting
  fetchHistoryLogs(quoteId: string) {
    return this.api.httpGetMethod(API_END_POINTS.fetchHistoryLogs + quoteId,false, environment.SecUrl_two);
  }

  fetchDiscountRequest(quoteId: string) {
    return this.api.httpGetMethod(
      API_END_POINTS.fetchDiscountRequest + quoteId, false, environment.SecUrl_two
    );
  }

  fetchActionInfo(initiatorType: string) {
    return this.api.httpGetMethod(
      API_END_POINTS.fetchActionInfo + initiatorType, false, environment.SecUrl_two
    );
  }

  statusHistory(quoteId: string, body: any) {
    return this.api.httpPostMethods(
      API_END_POINTS.statusHistory + quoteId,
      body, false, this.newheaders, environment.SecUrl_two
    );
  }

  documentAttach(formData: any, params: any): Observable<any> {
    const headers = {"in-auth-token": sessionStorage.getItem('in-auth-token')}
    formData.append("attachType", "attachWrapper");
    return this.api.httpPostMethods(
      API_END_POINTS.documentAttach,
      formData,
      false,
      headers,
      environment.SecUrl_two,
      params
    );
  }

  referToDetails(params: any): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.referTo, false, "" ,"", params
    );
  }

  higherReportee(params: any): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.higherReportee, false, "" ,"", params
    );
  }

  policy(quoteId: any): Observable<any> {
    return this.api.httpGetMethod(
      API_END_POINTS.policy + quoteId,
      false,
      environment.SecUrl_two
    );
  }

  search_nsm(): Observable<any> {
    return this.api.httpGetMethod(API_END_POINTS.search_nsm);
  }

  nsmMailService(body: any): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.nsmMailService, body, false, this.newheaders, environment.SecUrl_two);
  }

  async downloadQuote(quoteId: string){
  await this.api.httpPostPdfDownload(API_END_POINTS.downloadQuote + quoteId, quoteId, this.newheaders, environment.SecUrl_two);
  }
  
  ctokenNsm(body: any): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.nsmCtoken, body);
  }

  getProfileData(cToken: string): Observable<any> {
    let headers = {
      "Content-Type": "application/json",
      ctoken: cToken
    }
    return this.api.httpGetMethod(API_END_POINTS.profile, false, "", headers);
  }

  validateLink(quoteId: string) {
    return this.api.httpGetMethod(API_END_POINTS.validateLink + quoteId, false, environment.SecUrl_two);
  }

  getDownloadDoc(docId: string, docType: string, docName: string) {
    return this.api.httpGetMethod(
      `${API_END_POINTS.downloadDocument}${docId}/${docType}/${docName}?download=1`,
      false,
      environment.SecUrl_two,
      undefined,
      undefined,
      "blob"
    );
  }
  /* nsm_discounting_ends */

  getCustomer360Url(data): Observable<any> {
    return this.api.httpPostMethods(API_END_POINTS.customer360Url, data);
  }

  getSearchList(url): Observable<any> {
    return this.http.get(url, { headers: new HttpHeaders(this.headers) });
  }

  // Emp Login
  empSignIn(data): Observable<any> {
    return this.http.post(environment.uconBaseUrl + API_END_POINTS.empOtplogin, data, {});
  }

  empVerifyOtp(data): Observable<any> {
    return this.http.post(environment.uconBaseUrl + API_END_POINTS.empVerifyOtp, data, {});
  }

  consumerLogin(data): Observable<any> {
    return this.http.post(environment.url + API_END_POINTS.consumerLogin, data, { headers: new HttpHeaders(this.newheaders) });
  }

  checkAccessPayAndSettl(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.payAndSettl, { headers: new HttpHeaders(this.newheaders) });
  }

  checkCdReplenishmentAccess(): Observable<any> {
    return this.http.get(environment.url + API_END_POINTS.CDReplenishmentAndCDPaymentMethod, { headers: new HttpHeaders(this.newheaders) });
  }

  validateSookshmaAndLaghuV2_0(data):Observable<any> {
    return this.http.get(this.SecUrl_two + "qms_bre_integration_aigc/aigcNBUserallowed?producer_code="+data,{headers: new HttpHeaders(this.headers)});
  }

}
