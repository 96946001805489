import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BaseServiceService } from "src/app/services/base-service.service";
import { ALLEACH_CONST, COMMON_WORDS } from "src/app/services/constants";
import { SessionStorageService } from "src/app/services/session-storage.service";
import { SwalService } from "src/app/services/swal.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Component({
  selector: "app-features-area",
  templateUrl: "./features-area.component.html",
  styleUrls: ["./features-area.component.scss"],
})
export class FeaturesAreaComponent implements OnInit {
  menuItems = [];
  browserName: string = "";
  profileData: any;
  policyServicingErrorMessage: any;
  policyServicingURL: any;
  role;
  ntId = '';
  lockstatus = false;
  authRole = '';
  showTapAndPay = 'false';
  menuItemsUconWrapperCheck = [];

  constructor(private service: BaseServiceService, private swalService: SwalService, public datePipe : DatePipe, private spinner: NgxSpinnerService, private sessionStorageService: SessionStorageService) {
    this.service.fnBrowserDetect();
    this.profileApi();
    this.menuItems = [
      {
        name: "Policy Servicing",
        key: "policy-servicing",
        iconPath: `${this.service.commonIconPath}/assets/images/LINEAR ICONS/PolicyServicing.svg`,
        authorizedRoles: ["broker", "agent"],
      },
      {
        name: "NEFT",
        key: "neft",
        iconPath: `${this.service.commonIconPath}/assets/images/LINEAR ICONS/neft-colored.svg`,
        authorizedRoles: ["all"],
      },
      // {
      //   name: "IPDS V1",
      //   key: "ipds-v1",
      //   iconPath: `${this.service.commonIconPath}/assets/images/LINEAR ICONS/IpdsV1.svg`,
      //   authorizedRoles: ["broker", "agent"],
      // },
      {
        name: "EMI Eligibility Check",
        key: "checkEligibility",
        iconPath: `${this.service.commonIconPath}/assets/images/LINEAR ICONS/emi-calculator-colored.svg`,
        authorizedRoles: ["broker", "agent"],
      },
      {
        name: "Fuel",
        key: "fuel",
        iconPath: `${this.service.commonIconPath}/assets/images/LINEAR ICONS/fuel-colored.svg`,
        authorizedRoles: ["all"],
      },
      {
        name: "CL Endorsement",
        key: "endorsement",
        iconPath: `${this.service.commonIconPath}/assets/images/LINEAR ICONS/Endorsement.svg`,
        authorizedRoles: ["all"],
      },
      {
        name: "Discrepancy",
        key: "discrepancy",
        iconPath: `${this.service.commonIconPath}/assets/images/LINEAR ICONS/Discrepencies-colored.svg`,
        authorizedRoles: ["all"],
      },
      {
        name: "Customer 360",
        key: "customer360",
        iconPath: `${this.service.commonIconPath}/assets/images/LINEAR ICONS/customer360.svg`,
        authorizedRoles: ["broker", "agent"],
      },
      // {
      //   name: "Intellicus",
      //   key: "intellicus",
      //   iconPath: `${this.service.commonIconPath}/assets/images/LINEAR ICONS/intellicus.svg`,
      //   authorizedRoles: ["all"],
      // },
    ];

    this.menuItemsUconWrapperCheck = [
      {
        name: "CD Replenishment",
        key: "cdReplenishment",
        iconPath: `${this.service.commonIconPath}assets/images/LINEAR ICONS/CD-Replenishment-colored.svg`,
        authorized: false,
      },
      {
        name: "Tap Payment Link",
        key: "single-link",
        iconPath: `${this.service.commonIconPath}assets/images/LINEAR ICONS/tap_icon.svg`,
        authorized: false
      },
      {
        name: "Tap Payment Bulk Upload Link",
        key: "bulk-upload",
        iconPath: `${this.service.commonIconPath}assets/images/LINEAR ICONS/tap_icon.svg`,
        authorized: false
      },
    ]
  }

  ngOnInit(): void {
    this.spinner.show();
    this.checkLockedProducer();

    if (sessionStorage.getItem('role') != '' && sessionStorage.getItem('role') != undefined && sessionStorage.getItem('role') != null) {
      this.role = sessionStorage.getItem('role').toLowerCase();
    }

    this.authRole = sessionStorage.getItem('auth-role') ? sessionStorage.getItem('auth-role') : '';
    this.lockedProducerLeftmenu();
    this.checkAccessForTapPay();
    this.checkCdReplenishmentAccess();
    this.spinner.hide();
  }

  lockedProducerLeftmenu(){
    this.menuItems.forEach(element => {
      if(element.key == 'endorsement' && this.lockstatus){
        element.hide=true;
      }else if(element.key == 'cdReplenishment' && (['',null,undefined].includes(sessionStorage.getItem('profile_role')) || (sessionStorage.getItem('profile_role').toLowerCase() != 'producer' && sessionStorage.getItem('profile_role').toLowerCase() != 'sub producer' && sessionStorage.getItem('profile_role').toLowerCase() != 'data entry operator'))){
        element.hide = true;
      }
    });
  }

  checkLockedProducer() {
    let lockStatus = sessionStorage.getItem('lockstatus');
    if (lockStatus && lockStatus.toUpperCase() === 'LOCKED') {
      this.lockstatus = true;
    }
  }

  profileApi() {
    var token =
      sessionStorage.getItem("impersonate") == "true"
        ? sessionStorage.getItem("auth-token")
        : sessionStorage.getItem("in-auth-token");
    this.service.custom_profile(token).subscribe(
      (response) => {
        if (response.status === 0) {
          this.profileData = response.data;
          if (
            this.profileData.broker_code &&
            this.profileData.broker_code.length > 0
          ) {
            sessionStorage.setItem("broker_code", this.profileData.broker_code);
          }
          if (this.browserName === "safari") {
            this.spaNavigation();
          }
        } else {
          // Swal.fire('Oops...', 'Api Failed!', 'error')
        }
      },
      (err) => {
        // Swal.fire('Oops...', 'Server Timeout!', 'error')
      }
    );
  }

  async spaNavigation() {
    var role =
      sessionStorage.getItem("role") == "broker"
        ? "PRODUCER"
        : sessionStorage.getItem("role");
    var brcode =
      this.profileData.broker_code
        ? this.profileData.broker_code 
        : sessionStorage.getItem("producer_code")
        ? sessionStorage.getItem("producer_code") 
        : "";
    var brmobile =
      this.profileData.mobile_no != undefined ? this.profileData.mobile_no : "";
    var bremail =
     this.profileData.data.email_id ? this.profileData.data.email_id : this.profileData.alt_email ? this.profileData.alt_email : '';
    var brname =
      this.profileData.first_name != undefined
        ? this.profileData.first_name
        : "";
    var csmNtId =
      this.profileData.data.nt_login != undefined
        ? this.profileData.data.nt_login
        : "";

    var endosmentData = {
      b_role: role,
      b_code: brcode,
      b_mobile: brmobile,
      b_email: bremail,
      b_name: brname,
    };
    if (sessionStorage.getItem("auth-role") == "csm") {
      brcode = sessionStorage.getItem("producer_code");
      var endosmentCsmData = {
        b_role: role,
        csmNtId: csmNtId,
        b_code: brcode,
        b_mobile: JSON.parse(sessionStorage.getItem("producer_details"))
          .mobile_no,
        b_email: JSON.parse(sessionStorage.getItem("producer_details"))
          .email_id,
        b_name: JSON.parse(sessionStorage.getItem("producer_details"))
          .producer_name,
      };

      this.service.getmyRequestSPACsmUrl(endosmentCsmData).subscribe(
        (response) => {
          if (response.status === 0) {
            if (
              typeof response.data === "string" &&
              response.data.includes('"error":true,')
            ) {
              let obj = JSON.parse(response.data);
              this.policyServicingErrorMessage = obj.message;
              if (this.browserName !== "safari") {
                this.policyServicingError();
              }
            } else {
              this.policyServicingErrorMessage = "";
              this.policyServicingURL = response.data;
              if (this.browserName !== "safari") {
                window.open(this.policyServicingURL, "_blank");
              }
            }
          } else {
            this.policyServicingErrorMessage = response.txt;
          }
        },
        (err) => {
          Swal.fire("Oops...", "Server Timeout!", "error");
        }
      );
    } else {
      this.service.getmyRequestSPAUrl(endosmentData).subscribe(
        (response) => {
          if (response.status === 0) {
            if (
              typeof response.data === "string" &&
              response.data.includes('"error":true,')
            ) {
              let obj = JSON.parse(response.data);
              this.policyServicingErrorMessage = obj.message;
              if (this.browserName !== "safari") {
                this.policyServicingError();
              }
            } else {
              this.policyServicingErrorMessage = "";
              this.policyServicingURL = response.data;
              if (this.browserName !== "safari") {
                window.open(this.policyServicingURL, "_blank");
              }
            }
          } else {
            this.policyServicingErrorMessage = response.txt;
          }
        },
        (err) => {
          Swal.fire("Oops...", "Server Timeout!", "error");
        }
      );
    }
  }

  policyServicingError() {
    Swal.fire("Oops...", this.policyServicingErrorMessage, "error");
    return
  }

  handleMenuItemClick(menuItem) {
    switch(menuItem) {
      case 'mytransactions': {
        this.navigation('mytransactions');
        break;
      }
      case 'policy-servicing': {
        this.spaNavigation();
        break;
      }
      case 'ipds-v1': {
        this.navigate();
        break;
      }
      case 'fuel': {
        this.navigateToFuel();
        break;
      }
      case 'customer360': {
        this.goToCustomer360();
        break;
      }
      default: {
        this.navigation(menuItem);
        break;
      }
    }
  }

  goToCustomer360() {
    let producer_details = JSON.parse(sessionStorage.getItem("producer_details"));
    const role = "PRODUCER";
    let producerCode = "";
    let producerEmailId = "";
    let producerName = "";
    let producerMobileNumber = '';
    
    if (
      this.sessionStorageService.getItem("auth-role") == ALLEACH_CONST.csm
    ) {
      producerCode =
        this.sessionStorageService.getItem("producer_code") ||
        this.sessionStorageService.getItem("parent_code") ||
        this.sessionStorageService.getItem("broker_code");
      producerEmailId = producer_details.email_id;
      producerName = producer_details.producer_name;
      producerMobileNumber = producer_details.mobile_no;
    } else {
      producerCode = this.profileData.broker_code
        ? this.profileData.broker_code
        : this.profileData.parent_code
        ? this.profileData.parent_code
        : "";
      producerEmailId = this.profileData.data.email_id
        ? this.profileData.data.email_id
        : "";
      producerName =
        this.profileData.name != undefined
          ? this.profileData.name
          : "";
      producerMobileNumber = this.profileData.mobile_no != undefined 
                        ? this.profileData.mobile_no : '';
    }

    const searchBy = 'producerCode';
    const searchValue = producerCode;

    const customer360Data = {
      producerCode,
      producerMobileNumber,
      producerEmailId,
      role,
      producerName,
      searchBy,
      searchValue
    };

    this.service.getCustomer360Url(customer360Data).subscribe((res) => {
      if (res.status === 0) {
        const customer360Url = res?.data?.data?.redirectUrl;
        if(customer360Url){
          window.open(customer360Url, '_blank');
        } else {
          this.swalService.error('Error', 'Customer 360 URL not found');
        }
      }
    })
   
  }

  async navigation(page) {
    sessionStorage.removeItem('search_filters');
    sessionStorage.removeItem('search_form_details');
    sessionStorage.removeItem('search_filter_details');

    const { origin } = window.location;
    const href = origin.indexOf('localhost') === -1 ? '/ipdsv2/login/' : '/';

   if (href == '/') {
      window.location.href = `http://localhost:4201${href}#${href}${page}`;
    }
    else if (page == "helpTicket") {
      window.location.href = `${origin}/ipdsv2/support/#/${page}`;
    }
    else if (page == "checkEligibility") {
      window.location.href = `${origin}/ipdsv2/payment/#/${page}`;
    }
    else if(page == "endorsement"){
      if(this.role && ['underwriter','ops'].includes(this.role)){
        window.location.href = `${origin}/ipdsv2/qms/nonrater-endorsement`;
        return;
      }
      const value = await  this.validateEndorsementUser();
        if(value == true){
          window.location.href = `${origin}/ipdsv2/qms/nonrater-endorsement`;
        }else{
          Swal.fire('Endorsement not Allowed','','error');
        }
    }
    else if(page == "cdReplenishment"){
      window.location.href = `${origin}/ipdsv2/payment/#/cdReplenishment`
    }
    else if(page == 'neft'){
      window.location.href = `${origin}/ipdsv2/payment/#/payercodecreation`
    }
    else if(page == 'discrepancy'){
      window.location.href =`${origin}/ipdsv2/discrepancy/#/discrepency/discrepency-listing`;
    }
    else {
      window.location.href = `${origin}${href}#${page}`;
    }
  }

  navigate() {
    const date = new Date()
    let producer_code = '';
    if (this.profileData.broker_code && this.profileData.broker_code.length > 0) {
      producer_code = this.profileData.broker_code;
    }
    else if (sessionStorage.getItem('producer_code') != null && sessionStorage.getItem('producer_code') != undefined && sessionStorage.getItem('producer_code').length > 0) {
      producer_code = sessionStorage.getItem('producer_code');
    }

    if (producer_code.length > 0) {
      const transformedDte = this.datePipe.transform(date, 'yyyyMMddHHmmSSMS');
      const decryptedUrl = btoa(producer_code + "|" + this.service.navigation_token + "|" + transformedDte)
      const urlString = this.service.ipdsv1Navigation + decryptedUrl + "&src=IPDSV2";
      window.open(urlString, '_blank');
    }
    else {
      Swal.fire('Oops...', 'Producer Code Not Found', 'error')
    }
  }

  async navigateToFuel() {
    await new Promise((resolve) => {
      this.csmProfileRoleApi(resolve);
    });
    // "nt_id":  "anaraya2",
    // "time":  "1642673715694",
    // "source":  "V2"
    // const date = Date.now();
    let data = {
      "nt_id": this.ntId,
      "time": Date.now().toString(),
      "source": "V2"
    };
    if (this.ntId.length > 0) {
      this.service.encryption(data).subscribe((response) => {
        if (response.status === 0) {
          const url = `${this.service.fuelPath}query_NT_ID=${response.data.nt_id}&query_time=${response.data.time}&query_source=${response.data.source}`
          window.open(url, '_blank');
        }
      });
    }
    else {
      Swal.fire('Oops...', 'NT ID Not Found', 'error');
    }
  }

  csmProfileRoleApi(resolve) {
    this.service.csmProfile().subscribe(response => {
      if (response.status === 0) {
        // var roleName = { "role_id": response.data.roles[0] };
        if (Object.keys(response.data).length > 0 && Object.keys(response.data.data).length > 0 && response.data.data.hasOwnProperty('nt_login') && response.data.data.nt_login.length > 0) {
          this.ntId = response.data.data.nt_login;
          resolve('resolved');
        }

      } else {
        // Swal.fire('Oops...', 'Api Failed!', 'error')
      }
    }, err => {
      //Swal.fire('Oops...', 'Server Timeout!', 'error')
    })
  }

  validateEndorsementUser(){
    this.spinner.show();
    return new Promise((resolve,reject)=>{
      let producer_code = ['',null,undefined].includes(sessionStorage.getItem('producer_code'))?sessionStorage.getItem('broker_code'):sessionStorage.getItem('producer_code');
      this.service.validateEndorsementUser(producer_code).subscribe((response)=>{
        if(response.status==0&& response.data=='Success'){
          this.spinner.hide();
          return resolve(true);
        }
        else{
          this.spinner.hide();
          return resolve(false);
        }
      },err=>{
        this.spinner.hide();
        return resolve(false);
      });
    });
  }

  // intellicus_token(){
  //   this.spinner.show();
  //   return new Promise((resolve,reject)=>{
  //     let producer_code = sessionStorage.getItem("producer_code") || sessionStorage.getItem("broker_code") || sessionStorage.getItem("parent_code");
  //     let data = {producer_cd: producer_code};
  //     this.service.intellicus_token(data).subscribe((response)=>{
  //       if(response.status==0 && response?.data?.IntellicusToken){
  //         this.spinner.hide();
  //         return resolve(response?.data?.IntellicusToken);
  //       }
  //       else{
  //         this.spinner.hide();
  //         return resolve('');
  //       }
  //     },err=>{
  //       this.spinner.hide();
  //       return resolve('');
  //     });
  //   });
  // }

  redirectToTapPay(page) {

    const userType = sessionStorage.getItem('usertype');
    let userInfo;

    if(userType === COMMON_WORDS.producer) {
      userInfo = sessionStorage.getItem('producer_code') ? sessionStorage.getItem('producer_code') : sessionStorage.getItem('broker_code');
    } else if(userType === COMMON_WORDS.employee) {
      let profileData = sessionStorage.getItem('profile') ? JSON.parse(sessionStorage.getItem('profile')) : null;
      if(profileData) {
        userInfo = profileData?.data?.nt_login;
      }
    }

    if(!userInfo) {
      this.swalService.error('User info not found');
      return;
    }

    const body = {
      page,
      userInfo: userInfo
    }
    
    this.spinner.show();
    this.service.consumerLogin(body).toPromise().then((res: any) => {
      if (res?.status === 0) {
        const { data } = res;
        if (data?.data) {
          window.open(`${environment.tapPayUrl}?token=${data?.data}`, '_blank');
        }
      }
      this.spinner.hide();
    }).catch((err) => {
      this.swalService.error(err)
      this.spinner.hide();
    });
  }

  checkAccessForTapPay() {
    this.service.checkAccessPayAndSettl().toPromise().then((res: any) => {
      if(res?.status === 0) {
        const { data } = res;
        this.menuItemsUconWrapperCheck[1].authorized = data;
        this.menuItemsUconWrapperCheck[2].authorized = data;
      }
    }).catch((err) => {
      this.swalService.error(err)
    });
  }

  checkCdReplenishmentAccess() {
    this.service.checkCdReplenishmentAccess().toPromise().then((res: any) => {
      if(res?.status === 0) {
        const { data } = res;
        this.menuItemsUconWrapperCheck[0].authorized = data;
      }
    }).catch((err) => {
      this.swalService.error(err)
    });
  }

  redirectUconWrapper(key) {
    switch(key) {
      case 'single-link': {
        this.redirectToTapPay('single-link');
        break;
      } 
      case 'bulk-upload': {
        this.redirectToTapPay('bulk-upload');
        break;
      }
      case 'cdReplenishment': {
        this.navigation('cdReplenishment');
        break;
      }
    }
  }

}
