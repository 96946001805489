import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-mytransaction',
  templateUrl: './mytransaction.component.html',
  styleUrls: ['./mytransaction.component.sass'],
})
export class MytransactionComponent implements OnInit {
 
  lockstatus: any;
  role = '';
  userProfile: any;
  showCommissionOnDemand = false;
  showTapAndPay = "false";

  constructor(public globals: Globals, public router: Router, public service: BaseServiceService, public datePipe: DatePipe, public translate: TranslateService) {
  }
  back() {
    this.router.navigateByUrl('/dashboard');
  }

  buttonav(arg) {
    const { origin } = window.location;
    if (arg == 'payinslip') {
      window.location.href = `${origin}/ipdsv2/payment/#/createpayinslip`;
    } else if (arg == 'instapolicy') {
      window.location.href = `${origin}/ipdsv2/payment/#/payinstant`;

    }
    else if (arg == 'commissionOnDemand') {
      window.location.href = `${origin}/ipdsv2/payment/#/commisionOnDemand`
    }
    else if(arg == 'eodpayinslip'){
      window.location.href = `${origin}/ipdsv2/payment/#/createpayinslip?eod=true`
    }
    else if(arg == 'standAlonePayment'){
      window.location.href = `${origin}/ipdsv2/payment/#/payment?standAlone=true`
    }
    else if(arg == 'payLater'){
      window.location.href = `${origin}/ipdsv2/payment/#/payLaterPayment`
    }
    else if (arg == 'instapolicytravel') {
      window.location.href = `${origin}/ipdsv2/payment/#/payinstant?eodTravel=true`;

    }
    else if(arg == 'eodpayinsliptravel'){
      window.location.href = `${origin}/ipdsv2/payment/#/createpayinslip?eodTravel=true`
    }
  }
  // : void
  async ngOnInit() {

    if (sessionStorage.getItem('lockstatus') != undefined && sessionStorage.getItem('lockstatus') != '') {
      this.lockstatus = sessionStorage.getItem('lockstatus');
    }
    if (this.lockstatus == 'LOCKED') {
      let lockstatusmsg = sessionStorage.getItem('lockstatusmsg');
      let loackstatusErrorCode = sessionStorage.getItem('loackstatusErrorCode');
      let finalmsg = '[Errorcode :' + loackstatusErrorCode + ']' + lockstatusmsg;
      Swal.fire( this.translate.instant("staticTs.locked"), finalmsg, this.translate.instant("staticTs.error"));
    }
  
    
    let role = sessionStorage.getItem('role');
    if (role?.length > 0) {
      role = role.toLowerCase();
      this.role = role;
    }
    
    this.userProfile = sessionStorage.getItem('user_profile') ? JSON.parse(sessionStorage.getItem('user_profile')) : {};
    this.checkCommissionOnDemand();
  }

  navigate() {
    const date = new Date();
    let producer_code = '';

    if (sessionStorage.getItem('broker_code')?.length > 0) {
      producer_code = sessionStorage.getItem('broker_code');
    }
    else if (sessionStorage.getItem('producer_code')?.length > 0) {
      producer_code = sessionStorage.getItem('producer_code');
    }

    if (producer_code.length > 0) {
      const transformedDte = this.datePipe.transform(date, 'yyyyMMddHHmmSSMS')
      const decryptedUrl = btoa(producer_code + "|" + this.service.navigation_token + "|" + transformedDte)
      const urlString = this.service.ipdsv1Navigation + decryptedUrl + "&src=IPDSV2";
      // window.location.href = urlString
      window.open(urlString, '_blank');
    }
    else {
      Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.producerCodeNotFound"), this.translate.instant("staticTs.error"))
    }

  }

  checkCommissionOnDemand() {
    if (this.userProfile?.role?.toLowerCase() === 'producer') {
      if (this.userProfile?.category?.toLowerCase() === 'internal posp' || this.userProfile?.category?.toLowerCase() === 'agent individual') {
        this.showCommissionOnDemand = true;
      }
    }
    if (this.userProfile?.role?.toLowerCase() === 'sub producer') {
      if (this.userProfile?.category?.toLowerCase() === 'agent individual') {
        this.showCommissionOnDemand = true;
      }
    }
  }
}
